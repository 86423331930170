import * as React from 'react'
import Layout from '../components/Layout'
import VisibilitySensor from 'react-visibility-sensor'
import CountUp from 'react-countup'

import arcanum_hero from '../images/arcanum_hero.mp4'
import arcanum_hero_poster from '../images/arcanum-hero-poster.webp'
import people_icon from '../images/hm-people.svg'
import world_map from '../images/hm-worldmap.svg'
import mission_icon from '../images/hm-missionicon.svg'
import reinventing_space from '../images/reinventing-space.webp'
import arcanum_patch from '../images/arcanum-patch.webp'
import arcanum_bg from '../images/arcanum-hero.webp'
import dubai_skyline from '../images/dubai-skyline.webp'

let readyToCount = false;

function triggerCountup() {
    readyToCount = true
}

export default function Index() {
    return (
        <Layout title='Conex Research'>
            <title>Conex Research</title>
            <section className='grid grid-cols-1 grid-rows-1 w-screen lg:h-screen'>
                <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    src={arcanum_hero}
                    className='col-start-1 row-start-1 w-full h-full object-cover'
                    type='video/webm'
                    poster={arcanum_hero_poster}
                />
                <div className='col-start-1 row-start-1 w-full h-full flex justify-center items-center text-white pt-14 md:pt-28 ws:pt-56 z-10'>
                    <a href='/arcanum'>
                        <h2 className='text-xl sm:text-2xl ws:text-4xl hover:underline'>Our Inaugural Mission {">"}</h2>
                    </a>
                </div>
            </section>


            <section className='relative w-screen h-fit flex flex-col justify-center items-center my-14 sm:my-28'>
                <div className='w-4/5 sm:w-2/3 flex flex-col justify-center items-center text-center'>
                    <h1 className='mt-10 sm:mt-0 text-4xl sm:text-6xl ws:text-7xl font-extrabold text-conex-blue dark:text-blue-600'>Who are we?</h1>
                    <p className='mt-10 font-light sm:text-2xl ws:text-3xl'>
                        <strong className='font-bold'>Con</strong>ceptual <strong className='font-bold'>Ex</strong>ploration (<strong className='font-bold'>Conex</strong>) Research is an international team of students and young professionals developing science and engineering-backed space mission proposals.<br/><br/>
                        Through our comprehensive approach to mission design, we offer experience across several disciplines including astrodynamics, systems engineering, and spacecraft design.<br/><br/>
                        With our industry partners, we aim to upskill our members and give them the experience needed to launch their careers in the space sector.
                    </p>
                </div>

                <div className='w-2/3 md:w-4/5 lg:w-2/3 ws:w-1/4 h-fit my-10 ws:mt-20 flex flex-col sm:flex-row gap-6'>
                    <div className='basis-1/3 flex flex-row'>
                        <div className='basis-2/5 h-2/3 p-2'>
                            <img src={people_icon}/>
                        </div>
                        <div className='basis-3/5 flex flex-col justify-center items-center'>
                        <CountUp start={0} end={53} duration={2}>
                                {({ countUpRef, start }) => (
                                    <div>
                                        <VisibilitySensor onChange={() => {if (readyToCount) start(); else triggerCountup();}}>
                                            <h2 className='font-black text-4xl sm:text-5xl' ref={countUpRef} />
                                        </VisibilitySensor>
                                    </div>
                                )}
                            </CountUp>
                            <h3 className='font-medium text-2xl sm:text-3xl'>Members</h3>
                        </div>
                    </div>
                    <div className='basis-1/3 flex flex-row'>
                        <div className='basis-2/5 flex justify-center items-center'>
                            <img src={world_map}/>
                        </div>
                        <div className='basis-3/5 flex flex-col justify-center items-center'>
                        <CountUp start={0} end={20} duration={2}>
                                {({ countUpRef, start }) => (
                                    <div>
                                        <VisibilitySensor onChange={() => {if (readyToCount) start(); else triggerCountup();}}>
                                            <h2 className='font-black text-4xl sm:text-5xl' ref={countUpRef} />
                                        </VisibilitySensor>
                                    </div>
                                )}
                            </CountUp>
                            <h3 className='font-medium text-2xl sm:text-3xl'>Countries</h3>
                        </div>
                    </div>
                    <div className='basis-1/3 flex flex-row'>
                        <div className='basis-2/5 p-2'>
                            <img src={mission_icon}/>
                        </div>
                        <div className='basis-3/5 flex flex-col justify-center items-center'>
                            <CountUp start={0} end={2} duration={1}>
                                {({ countUpRef, start }) => (
                                    <div>
                                        <VisibilitySensor onChange={() => {if (readyToCount) start(); else triggerCountup();}}>
                                            <h2 className='font-black text-4xl sm:text-5xl' ref={countUpRef} />
                                        </VisibilitySensor>
                                    </div>
                                )}
                            </CountUp>
                            <h3 className='font-medium text-2xl sm:text-3xl'>Missions</h3>
                        </div>
                    </div>

                </div>

            </section>


            <section className='relative block w-screen h-fit'>
                <div className='w-full h-2/3'>
                    <img className='h-full w-full object-cover' src={reinventing_space}/>
                </div>
                <div className='w-full h-fit flex flex-col justify-center items-center bg-shell p-8 sm:p-14'>
                    <q className='font-light text-xl text-center sm:text-4xl ws:text-5xl mb-10 sm:mx-9'>
                        Conex provides quantitative analysis of near-future mission profiles,<br/>
                        helping define the future landscape of space exploration
                    </q>
                    {/* <p className='text-2xl sm:text-3xl font-semibold'>Tom Dixon</p>
                    <p className='text-lg sm:text-xl'>Designer</p> */}
                </div>
            </section>


            <section className='relative w-screen h-fit sm:h-full flex flex-col lg:flex-row justify-center items-center my-20 px-8 ws:mb-36'>

                <div className='flex flex-col basis-1/3 items-center'>
                    <img className='h-auto w-1/2 object-cover drop-shadow-xl' src={arcanum_patch}/>
                    <h1 className='mt-5 font-black text-3xl sm:text-6xl ws:mt-20'>ARCANUM</h1>
                    <h1 className='mt-1 font-semibold text-2xl sm:text-4xl'>Our Inaugural Mission</h1>
                    <a className='mt-1 sm:mt-5 text-xl sm:text-2xl' href='/arcanum'>
                        <h2>Learn More {">"}</h2>
                    </a>
                </div>

                <div className='h-fit sm:h-2/5 flex flex-row sm:basis-2/3 px-5 mt-7 lg:mt-0 sm:px-20'>
                    <img className='h-2/3 w-full object-cover object-bottom rounded-xl' src={arcanum_bg} alt='Sommerville spacecraft with Neptune in background'/>
                </div>

            </section>


            <section className='grid grid-cols-1 grid-rows-1 w-screen h-fit mb-10'>

                <img className='col-start-1 row-start-1 h-full sm:w-full object-cover object-bottom' src={dubai_skyline}/>

                <div className='col-start-1 row-start-1 h-fit sm:h-full w-full flex flex-col justify-center my-20 sm:my-0 sm:mt-0 pl-8 sm:pl-28'>
                    <h1 className='font-semibold text-white text-2xl sm:text-6xl ws:text-8xl'>Conex's visit to the<br/>IAC 2021 in Dubai</h1>
                    <a className='mt-1 sm:mt-5 text-lg sm:text-2xl ws:text-4xl text-white hover:underline' href='/news/IAC2021'>
                        <h2>Read More {">"}</h2>
                    </a>
                </div>
            </section>

        </Layout>
    )
}
